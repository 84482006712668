.form {
  display: flex;
}
.wrapper h1 {
  font-size: var(--xl);
  margin-bottom: 10px;
}
.wrapper p {
  font-size: var(--md);
}
.input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--text-color);
  outline: none;
  color: var(--text-color);
  min-width: 300px;
  padding: 10px 0;
}
.input::placeholder {
  color: var(--text-color);
  opacity: 1;
}
.button {
  background-color: var(--button-color);
  border: 1px solid var(--button-border-color);
  color: var(--button-text-color);
  cursor: pointer;
  padding: 10px;
}
.button:hover {
  background-color: var(--button-on-hover-color);
  border: 1px solid var(--button-on-hover-border-color);
  color: var(--button-on-hover-text-color);
}
.error_msg,
.api_error {
  margin-top: var(--container-padding);
  color: red;
}
.success {
  margin-top: var(--container-padding);
}
.input_btn_wrapper {
  margin-top: 30px;
}
@media screen and (max-width: 980px) {
  .input {
    min-width: auto;
    width: 100%;
  }
  .form {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .button {
    margin-top: 20px;
  }
}
