.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-color);
  color: var(--text-color);
  margin-top: var(--container-margin);
  padding: var(--container-padding) 0;
}
.footer_logo {
  width: 15%;
  padding: 20px;
}
.link {
  margin: 5px;
}
.link:hover {
  text-decoration: underline;
}
.copyright {
  font-size: var(--sm);
  margin: 5px;
}
@media screen and (max-width: 980px) {
  .footer {
    margin-top: var(--container-margin-mobile);
  }
  .footer_logo {
    width: 40%;
  }
  .footer p {
    font-size: var(--lg);
  }
  .footer .copyright {
    font-size: var(--sm);
  }
}
