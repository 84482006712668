.sticky_btn_section {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  justify-content: center;
  filter: blur(90%);
  align-items: center;
  box-shadow: var(--box-shadow-all);
  position: sticky;
  z-index: var(--z-index-sticky);
  top: auto;
  bottom: -130px;
  left: 0;
  right: 0;
  transition: all 0.4s;
  padding: var(--container-padding) 0;
}
.sticky_btn_section a {
  text-align: center;
}
.sticky_btn {
  width: 50%;
  background-color: var(--button-color);
  height: 76px;
  color: var(--button-text-color);
  border-radius: 5px;
  border: 1px solid var(--button-border-color);
  font-weight: var(--text-weight-black);
  display: flex;
  align-items: center;
  width: 500px;
  height: 60px;
  margin-bottom: var(--container-margin);
  position: relative;
}
.sticky_btn:hover {
  background-color: var(--button-hover-color);
  color: var(--button-hover-text-color);
  border-color: var(--button-hover-border-color);
}
.sticky_btn p {
  flex: 0 0 100%;
  padding-top: 5px;
}
.bottom_text p {
  margin-top: 0;
  font-size: var(--xl);
  font-weight: var(--text-weight-black);
  color: var(--text-color);
}
.sticky_btn_icon {
  position: absolute;
  right: 20px;
  color: var(--button-text-color);
}
@media screen and (max-width: 980px) {
  .sticky_btn_section a {
    width: 90%;
  }
  .sticky_btn {
    width: 100%;
    margin-bottom: 0;
  }
  .sticky_btn p {
    font-size: var(--xl);
    padding: 0 30px;
  }
  .bottom_text p {
    margin: 10px;
    margin-bottom: 0;
    font-size: var(--lg);
    text-align: center;
  }
}
