.container {
  padding: 20px 0;
  background-color: var(--background-color);
  color: var(--text-color);
}
.wrapper {
  max-width: 1100px;
  margin: 0 auto;
}
.logo_wrapper {
  max-width: 180px;
}
.logo_links_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.links_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.links_wrapper a {
  font-size: var(--sm);
  color: var(--text-color);
  text-decoration: none;
}
.copyright {
  font-size: var(--sm);
}
.pages_links {
  display: flex;
  gap: 100px;
}
.pages_links p,
.pages_links a {
  font-size: var(--lg);
  margin-bottom: 30px;
  color: var(--text-color);
  text-decoration: none;
}
.pages_links p:hover,
.pages_links a:hover,
.links_wrapper a:hover {
  text-decoration: underline;
}
.links {
  display: flex;
  flex-direction: column;
}
.links button {
  background-color: transparent;
  border: none;
  outline: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  color: var(--text-color);
}
.links a,
.links button {
  font-size: var(--xl);
  font-weight: var(--text-weight-black);  
}

.links a {
  margin-bottom: 20px;
}

.links a:hover,
.links button:hover {
  text-decoration: underline;
}
.pages_form_wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.mobile_logo {
  display: none;
}
.desktop_logo,
.desktop_social {
  display: block;
}
.right_aligned {
  justify-content: flex-end;
  width: 100%;
}
@media screen and (max-width: 980px) {
  .mobile_logo {
    display: block;
  }
  .desktop_logo,
  .desktop_social {
    display: none;
  }
  .pages_form_wrapper {
    flex-direction: column;
  }
  .container {
    padding: 10px;
  }
  .pages_links {
    margin: 20px 0;
  }
  .pages_links a {
    margin-bottom: var(--container-padding);
  }
  .pages_links p {
    font-size: var(--md);
    margin-bottom: 20px;
  }
  .pages_links a,
  .pages_links button {
    font-size: var(--lg);
  }
  .links {
    gap: 0;
  }
  .links_wrapper {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: var(--container-padding);
  }
}
