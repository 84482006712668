.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 12px;
  background-color: var(--background-color);
  color: var(--text-color);
  padding-top: 25px;
  padding-bottom: 15px;
}
.footer_logo {
  width: 200px;
}
.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 12px;
}
.link {
  font-size: var(--lg);
  line-height: 20px;
}
.link:hover {
  color: var(--link-hover-color);
}
.copyright {
  font-size: var(--sm);
}
@media screen and (max-width: 980px) {
  .footer {
    padding-top: 30px;
    padding-bottom: 25px;
  }
  .footer p {
    font-size: var(--lg);
  }
  .footer .copyright {
    font-size: var(--sm);
  }
}
