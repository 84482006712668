.body_section_container {
  display: flex;
  flex-direction: column;
  gap: var(--container-margin);
  background-color: var(--background-color);
}
.body_section_container > div:last-child {
  border-bottom: none;
}
@media screen and (max-width: 980px) {
  .body_section_container {
    gap: var(--container-margin-mobile);
  }
}
